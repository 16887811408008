<template>
  <h1 class="title">Contact Us</h1>

  <div class="container">
    <div class="contact">
      <form @submit.prevent="sendMail">
        <label>Name</label>
        <input type="text" v-model="mail.name" />

        <label>Mobile Number</label>
        <input type="text" v-model="mail.mobileNo" />

        <label>Email</label>
        <input type="text" v-model="mail.email" />

        <label>Subject</label>
        <textarea v-model="mail.content" style="height:200px"></textarea>

        <input type="submit" :value="submit" :disabled="isLoading" />
      </form>
    </div>
    <div class="address">
      <h3>You can also reach us on the following address:</h3>
      <div class="email">
        Mail us
        <a href="mailto:kutty@murshisoft.com">kutty@murshisoft.com</a>.<br />
      </div>
      <address class="india">
        <h3>Registered Office:</h3>
        <h2>India</h2>
        <h3>MurshiSoft LLP</h3>
        <h4>VII/42 Paloth</h4>
        <h4>Paloth Poovathikkal</h4>
        <h4>Malappuram</h4>
        <h4>Kerala - 673639</h4>
        <p>Mobile: +917356989449</p>
      </address>
      <address class="saudi">
        <h3>Technical Support:</h3>
        <h2>Saudi Arabia</h2>
        <h3>Jabal Shada Est</h3>
        <h4>Raghdan</h4>
        <h4>Al-Baha - 65421</h4>
        <p>Mobile: +966507602517</p>
      </address>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mail: {
        name: "",
        email: "",
        mobileNo: "",
        content: "",
      },
      isLoading: false,
      submit: "Submit",
    };
  },
  methods: {
    async sendMail() {
      this.isLoading = true;
      this.submit = "Sending please wait...";
      await this.$http({ method: "POST", url: "/mail/send", data: this.mail });
      this.isLoading = false;
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 30px;
  text-align: center;
  padding: 30px 0;
}
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;

  .contact {
    flex-basis: 50%;
    flex-grow: 0;
    input[type="text"],
    textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
    }

    input[type="submit"] {
      background-color: #4caf50;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    input[type="submit"]:hover {
      background-color: #45a049;
    }
  }
  .address {
    flex-basis: 50%;
    flex-grow: 0;
    padding-left: 30px;
    .email {
      font-size: 20px;
      padding: 20px 0;
    }
    .india {
      padding: 20px 0;
    }
    .saudi {
      padding: 20px 0;
    }
  }
}
</style>
